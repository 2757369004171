#menu.active {
  background-color: #ebf4fb;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 4px;
  color: inherit;
}

.menu-item:hover {
  background-color: #ebf4fb;
}

.menu-item:visited {
  color: inherit;
}
